<template>
  <v-container class="communication-form">
    <Breadcrumb :items="breadcrumb" :key="category.categoryId" />
    <CategoryTitle :category="category" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <div>
      <section>
        <v-row class="flex-md-row-reverse">
          <v-col cols="12" md="6">
            <category-block
              :target="category"
              position="position3"
              class="category-block category-block-3"
              :container="false"
            /> </v-col
          ><v-divider vertical></v-divider
          ><v-col cols="12" md="6">
            <article>
              <ebsn-meta
                :target="category"
                path="metaData.category_info.DESCRIPTION"
                :key="category.categoryId"
                tag="P"
              />
              <v-form class="d-flex flex-wrap" ref="form">
                <v-row no-gutters>
                  <!-- FIRST NAME -->
                  <v-col class="pr-sm-2" cols="12" sm="6">
                    <v-text-field
                      v-model="formData.firstName"
                      :placeholder="`${$t('contact.name')} *`"
                      :rules="[requiredRules()]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- LAST NAME -->
                  <v-col class="pl-sm-2" cols="12" sm="6">
                    <v-text-field
                      v-model="formData.lastName"
                      :placeholder="`${$t('contact.surname')} *`"
                      :rules="[requiredRules()]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- EMAIL -->
                  <v-col class="pr-sm-2" cols="12" sm="6">
                    <v-text-field
                      v-model="formData.email"
                      :placeholder="`${$t('contact.email')} *`"
                      :rules="emailRules"
                      :error-messages="errors"
                      type="email"
                      autocomplete="off"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h5 class="text-uppercase">
                      {{ $t("contact.informativa.title") }}
                    </h5>
                  </v-col>
                  <!-- PRIVACY -->
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      :rules="checkboxRule"
                      required
                      v-model="formData.acceptPrivacyPolicies"
                    >
                      <template v-slot:label>
                        <div>
                          {{ $t("contact.informativa.privacy") }}
                          <a
                            class="ml-n1"
                            target="_system"
                            :href="pdfUrl"
                            @click="clicked"
                          >
                            {{ $t("contact.privacyLink") }}
                          </a>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <!-- PROFILING POLICY -->
                  <v-col cols="12" class="py-0">
                    <v-checkbox v-model="formData.acceptProfilingPolicies">
                      <template v-slot:label>
                        <div>
                          {{ $t("contact.informativa.profiling") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <!-- CTA -->
                  <v-col class="mt-3" cols="12">
                    <v-btn
                      color="primary"
                      @click="validate()"
                      :loading="loading"
                    >
                      {{ $t("communication.sendFormData") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </article>
          </v-col>
        </v-row>
      </section>
    </div>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </v-container>
</template>
<style lang="scss">
.communication-form {
  h1 {
    font-size: 18px;
  }
  .description p {
    font-size: 14px !important;
  }
  form {
    .v-input.v-input--checkbox .v-input__slot {
      display: flex;
      align-items: flex-start;
      label {
        margin: auto 0px;
      }
    }
  }
  .v-list-item {
    &__icon {
      min-width: 16px;
      min-height: 16px;
      background-color: var(--v-primary-base);
      border-radius: 100%;
      .v-icon {
        &:before {
          padding: 4px;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  form .v-input .v-input__slot {
    align-items: center;
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";
import get from "lodash/get";
import eurospinCustomService from "@/service/eurospinCustomService";
import cloneDeep from "lodash/cloneDeep";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Communication",
  mixins: [clickHandler, categoryMixins],
  components: {
    Breadcrumb,
    CategoryTitle
  },
  data() {
    return {
      errors: [],
      loading: false,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        acceptPrivacyPolicies: null,
        //if the user accepts provacy policy which is obligatory he is also accepting marketing policies
        acceptMarketingPolicies: true,
        acceptProfilingPolicies: false
      },

      requiredRules: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ]
    };
  },
  computed: {
    pdfUrl() {
      return get(this.category, "media[0].url", null);
    },
    checkboxRule() {
      return [
        this.formData.acceptPrivacyPolicies === true || "Campo obbligatorio"
      ];
    }
  },
  methods: {
    validate() {
      let _this = this;
      if (_this.$refs.form) {
        _this.valid = _this.$refs.form.validate();
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.valid) {
            _this.loading = true;
            const formData = cloneDeep(_this.formData);

            const mobilePrefix = "0039";
            formData.prefix = mobilePrefix;

            const formDataEmail = formData.email.trim();
            formData.email = formDataEmail;
            formData.slug =
              this.$ebsn.meta(this.category, "template_model.slug") ||
              this.category.slug;

            eurospinCustomService
              .subscribeNewsletter(formData)
              .then(function(data) {
                if (data) {
                  console.log(data);
                  if (data.response.status == 0) {
                    _this.loading = false;
                    _this.$router.push({
                      name: "ThankyouCommunication",
                      params: { communication: true }
                    });
                  } else {
                    _this.$refs.form.reset();
                    _this.loading = false;
                  }
                }
                console.log(data);
              });
          } else {
            console.log(_this.$refs.form.$children);
            const invalidFields = _this.$refs.form.$children.filter(
              field => !field.valid && field.type !== "button"
            );
            invalidFields[0].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
            invalidFields[0].$el.focus();
            console.log(invalidFields);
          }
        }
      }
    }
  },
  mounted() {
    this.formData.email = this.$route.query.email || null;
  },
  watch: {
    "$route.query.email": function(email) {
      this.formData.email = email;
    }
  }
};
</script>
